import React from "react"
import PropTypes from "prop-types"
import NavBar from "./navbar"
import Footer from "./footer"

import "../styles/layout.css"

const Layout = ({ children }) => {
  return (
    <div>
      <NavBar />
      {children}
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
