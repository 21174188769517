import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavbarBrand from "react-bootstrap/NavbarBrand"
import NavDropdown from "react-bootstrap/NavDropdown"
import { Link } from "gatsby"
import logo from "../images/logo.svg"

const NavBar = () => (
  <Navbar bg="light" expand="lg" sticky="top" className={"ipt-navbar bg-white"}>
    <div className="container">
      <NavbarBrand href={"/"}>
        <img
          src={logo}
          alt="Investment Property Tracker Logo"
          width={28}
          height={28}
          className="d-inline-block align-top"
        />
        <span className={"logo-text"}>
          <b>Investment</b> Property Tracker
        </span>
      </NavbarBrand>
      <Navbar.Toggle aria-controls="ipt-navbar-nav" />

      <Navbar.Collapse id="ipt-navbar-nav">
        <Nav className="ml-auto mt-2 mt-lg-0">
          <li className="nav-item ipt-nav-item">
            <Nav.Link
              className="ipt-nav-link"
              as={Link}
              to={"/blog"}
              activeClassName="active"
            >
              Blog
            </Nav.Link>
          </li>

          <NavDropdown title={"Tools"} className="ipt-nav-item">
            <NavDropdown.Item
              as={Link}
              to={"/tools/rental-yield-calculator"}
              className="ipt-dropdown-item ipt-nav-link"
            >
              Rental Yield Calculator
            </NavDropdown.Item>
          </NavDropdown>

          <li className="nav-item ipt-nav-item">
            <Nav.Link
              className="ipt-nav-link"
              as={Link}
              to={"/faq"}
              activeClassName="active"
            >
              FAQs
            </Nav.Link>
          </li>
          <li className="nav-item ipt-nav-item">
            <a
              href={"https://app.investmentpropertytracker.com.au/login"}
              className="btn btn-primary btn-rounded"
              role={"button"}
            >
              Log in
            </a>
          </li>
        </Nav>
      </Navbar.Collapse>
    </div>
  </Navbar>
)

export default NavBar
