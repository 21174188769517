import React from "react"
import { Facebook, Linkedin } from "react-feather"
import { Link } from "gatsby"
import "../styles/footer.css"

const Footer = () => (
  <footer className="footer-distributed">
    <div className={"container"}>
      <div className="footer-right">
        <p>Connect On</p>
        <a
          href="https://www.facebook.com/investmentpropertytracker"
          target="_blank"
          rel="noreferrer"
        >
          <Facebook size={22} />
        </a>
        <a
          href="https://www.linkedin.com/company/investment-property-tracker"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin size={22} />
        </a>
      </div>
      <div className="footer-left">
        <p className="footer-links">
          <Link to={"/blog"}>Blog</Link> |{" "}
          <Link to={"/tools/rental-yield-calculator"}>
            Rental Yield Calculator
          </Link>{" "}
          | <Link to={"/faq"}>FAQs</Link> |{" "}
          <Link to={"/privacy"}>Privacy Policy</Link> |{" "}
          <Link to={"/terms"}>Terms and Conditions</Link>
        </p>
        <p>
          <b>Investment</b> Property Tracker &copy; 2020. All rights reserved.
        </p>
        <p className="email-link">
          <b>Email</b> us at{" "}
          <a href="mailto:admin@investmentpropertytracker.com.au" target="_top">
            admin@investmentpropertytracker.com.au
          </a>
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
